import React from 'react';

// Images
import PostSlide_1 from "~images/blog/img/Blog-Image-1.jpg";
import PostImage from "~images/blog/img/Blog-Image-2.jpg";
import PostImage2 from "~images/blog/img/Blog-Image-3.jpg";
const BlogPosts = () => {
    return (
        <div className="col-12 col-lg-8 res-margin">
						
			{/* <!-- Blog post with image slider --> */}
			<article className="row blog-post">
				<div className="col-12">
					
					<header>
						<h3>
							<a href="blog-post">5 simple steps to keep your home kitchen clean</a>
						</h3>
						<div className="info">
							{/* <span>Matthew Johns</span> */}
							<span><a href="/">Sanitisation</a></span>
							{/* <span>January 14, 2022</span> */}
						</div>
					</header>

					<figure>
						<img src={PostSlide_1} className="img-responsive img-rounded" alt="" />
					</figure>
					
					<p>
					A clean home is a happy home. Every home chef need to ensure the cleaning and sanitisation of their kitchen.
					</p>

					<div className="post-footer clearfix">
						
						<ul className="post-meta">
					
							<li className="post-like d-none d-md-block">
								{/* <!-- Post counters --> */}
								<ul className="post-counters">
									<li>
										<a href="/">
											<i className="far fa-comment"></i> 12
										</a>
									</li>
									<li>
										<a href="/">
											<i className="far fa-heart"></i> 5
										</a>					
									</li>
								</ul>
							</li>

							<li className="post-read-more">
								{/* <!-- Read more --> */}
								<a href="blog-post" className="btn btn-default">Read More</a>
							</li>

							<li className="post-share d-none d-md-block">
								{/* <!-- Share --> */}
								<div className="share-panel share-btn">
									<p><i className="fas fa-share-alt"></i>Share</p>
									<ul>
										<li>
											<a href="/">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-facebook-f"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-pinterest"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-linkedin-in"></i>
											</a>
										</li>
									</ul>
								</div>
							</li>
							
						</ul>
						
					</div>

				</div>
			</article>
			
			{/* <!-- Blog post with single image --> */}
			<article className="row blog-post">
				<div className="col-12">
					
					<header>
						<h3>
							<a href="blog-post">How to create the perfect menu for your kitchen  </a>
						</h3>
						<div className="info">
							{/* <span>Alex Wesly</span> */}
							<span><a href="/">Menu Structure</a></span>
							{/* <span>December 30, 2021</span> */}
						</div>
					</header>
					
					<figure>
						<img src={PostImage} className="img-responsive img-rounded" alt="" />
					</figure>

					<p>
					Once you receive your permit to operate your home kitchen, you can turn your creative ideas into the perfect menu of your choice.
					</p>

					<div className="post-footer clearfix">
						
						<ul className="post-meta">
					
							<li className="post-like d-none d-md-block">
								{/* <!-- Post counters --> */}
								<ul className="post-counters">
									<li>
										<a href="/">
											<i className="far fa-comment"></i> 3
										</a>
									</li>
									<li>
										<a href="/">
											<i className="far fa-heart"></i> 7
										</a>					
									</li>
								</ul>
							</li>

							<li className="post-read-more">
								{/* <!-- Read more --> */}
								<a href="blog-post" className="btn btn-default">Read More</a>
							</li>

							<li className="post-share d-none d-md-block">
								{/* <!-- Share --> */}
								<div className="share-panel share-btn">
									<p><i className="fas fa-share-alt"></i>Share</p>
									<ul>
										<li>
											<a href="/">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-facebook-f"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-pinterest"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-linkedin-in"></i>
											</a>
										</li>
									</ul>
								</div>
							</li>
							
						</ul>
						
					</div>

				</div>
			</article>
			
			{/* <!-- Blog post with embed video --> */}
			<article className="row blog-post">
				<div className="col-12">
					
					<header>
						<h3>
							<a href="blog-post"> Benefits of eating healthy homely food</a>
						</h3>
						<div className="info">
							{/* <span>Richard Jackson</span> */}
							<span><a href="/">Homely Food</a></span>
							{/* <span>November 19, 2021</span> */}
						</div>
					</header>
					
					{/* <figure>
						<div className="video-responsive img-rounded">
							<iframe src="https://player.vimeo.com/video/34492064" title="The Little Ghost" allowFullScreen></iframe>
						</div>
					</figure> */}
						
					<figure>
						<img src={PostImage2} className="img-responsive img-rounded" alt="" />
					</figure>

					<p>
					Cooking at home gives an opportunity to eat the foods you love exactly how you enjoy eating them! Simply home made food can boost your immune system.
					</p>

					<div className="post-footer clearfix">
						
						<ul className="post-meta">
					
							<li className="post-like d-none d-md-block">
								{/* <!-- Post counters --> */}
								<ul className="post-counters">
									<li>
										<a href="/">
											<i className="far fa-comment"></i> 28
										</a>
									</li>
									<li>
										<a href="/">
											<i className="far fa-heart"></i> 16
										</a>					
									</li>
								</ul>
							</li>

							<li className="post-read-more">
								{/* <!-- Read more --> */}
								<a href="blog-post" className="btn btn-default">Read More</a>
							</li>

							<li className="post-share d-none d-md-block">
								{/* <!-- Share --> */}
								<div className="share-panel share-btn">
									<p><i className="fas fa-share-alt"></i>Share</p>
									<ul>
										<li>
											<a href="/">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-facebook-f"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-pinterest"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-linkedin-in"></i>
											</a>
										</li>
									</ul>
								</div>
							</li>
							
						</ul>
						
					</div>

				</div>
			</article>
			
			{/* <!-- Blog post with audio --> */}
			{/* <article className="row blog-post">
				<div className="col-12">
					
					<header>
						<h3>
							<a href="blog-post">Royal Oats ft. Waldo</a>
						</h3>
						<div className="info">
							<span>Jack Nichol</span>
							<span><a href="/">Photography</a></span>
							<span>August 14, 2021</span>
						</div>
					</header>
					 */}
					{/* <figure>
						<div className="video-responsive img-rounded">
							<iframe width="750" height="400" title="Royal Oats ft. Waldo" src="https://w.soundcloud.com/player/?visual=true&#038;url=http%3A%2F%2Fapi.soundcloud.com%2Ftracks%2F136949481&#038;show_artwork=true&#038;maxwidth=750&#038;maxheight=1000"></iframe>
						</div>
					</figure> */}
{/* 					
					<figure>
						<img src={PostImage2} className="img-responsive img-rounded" alt="" />
					</figure>


					<p>
						Sed lectus erat, dignissim tristique accumsan ut, mattis ac elit. Nunc at neque non augue faucibus eleifend. Quisque sagittis nisl id consectetur rhoncus.
						Integer condimentum quam auctor dui lobortis condimentum. Nunc sit amet orci ac tortor accumsan sagittis.
					</p>

					<div className="post-footer clearfix">
						
						<ul className="post-meta">
					
							<li className="post-like d-none d-md-block">
							
								<ul className="post-counters">
									<li>
										<a href="/">
											<i className="far fa-comment"></i> 9
										</a>
									</li>
									<li>
										<a href="/">
											<i className="far fa-heart"></i> 18
										</a>					
									</li>
								</ul>
							</li>

							<li className="post-read-more">
							
								<a href="blog-post" className="btn btn-default">Read More</a>
							</li>

							<li className="post-share d-none d-md-block">
					
								<div className="share-panel share-btn">
									<p><i className="fas fa-share-alt"></i>Share</p>
									<ul>
										<li>
											<a href="/">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-facebook-f"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-pinterest"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-linkedin-in"></i>
											</a>
										</li>
									</ul>
								</div>
							</li>
							
						</ul>
						
					</div>

				</div>
			</article>
			 */}
			{/* <!-- Blog post with text only --> */}
			{/* <article className="row blog-post">
				<div className="col-12">
					
					<header>
						<h3>
							<a href="blog-post">Forest Highway With Green Leaves</a>
						</h3>
						<div className="info">
							<span>John Orkwell</span>
							<span><a href="/">Lifestyle</a></span>
							<span>July 12, 2021</span>
						</div>
					</header>
					
					<p>
						Aliquam pulvinar hendrerit lorem sed dictum. Nullam ipsum nulla, ornare non metus nec, fermentum fermentum nisl. 
						Fusce mattis elit vestibulum nunc vehicula convallis ac elementum dolor. Etiam vitae tempor velit, bibendum quam. Aliquam eu faucibus sem. 
						Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos posuere cubilia himenaeos.
					</p>

					<div className="post-footer clearfix">
						
						<ul className="post-meta">
					
							<li className="post-like d-none d-md-block">
							
								<ul className="post-counters">
									<li>
										<a href="/">
											<i className="far fa-comment"></i> 32
										</a>
									</li>
									<li>
										<a href="/">
											<i className="far fa-heart"></i> 29
										</a>					
									</li>
								</ul>
							</li>

							<li className="post-read-more">
						
								<a href="blog-post" className="btn btn-default">Read More</a>
							</li>

							<li className="post-share d-none d-md-block">
							
								<div className="share-panel share-btn">
									<p><i className="fas fa-share-alt"></i>Share</p>
									<ul>
										<li>
											<a href="/">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-facebook-f"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-pinterest"></i>
											</a>
										</li>
										<li>
											<a href="/">
												<i className="fab fa-linkedin-in"></i>
											</a>
										</li>
									</ul>
								</div>
							</li>
							
						</ul>
						
					</div>

				</div>
			</article> */}
			
			{/* <!-- Pagination --> */}
			{/* <div className="col-12 pl-0 pr-0">
				
				<ul className="pagination justify-content-center justify-content-lg-start">
					
				
					<li>
						<a className="d-flex" href="/">
							<i className="fas fa-chevron-left"></i> 
							<span className="text">Prev</span>
						</a>
					</li>
					
					
					<li className="d-none d-md-block"><a href="/">1</a></li>
					<li className="d-none d-md-block"><a href="/">2</a></li>
					<li className="d-none d-md-block"><a className="active" href="/">3</a></li>
					<li className="d-none d-md-block"><a href="/">4</a></li>
					<li className="d-none d-md-block"><a href="/">5</a></li>
					
				
					<li>
						<a className="d-flex" href="/"> 
							<span className="text">Next</span>
							<i className="fas fa-chevron-right"></i> 
						</a>
					</li>
				
				</ul>
				
			</div> */}
			
		</div>
    );
};

export default BlogPosts;
